.landing_footer {
  background-color: #fdfdfd;
  height: 390px;
}

.landing_footer .landing_foot_top {
  max-width: 1440px;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: start;
  padding-top: 50px;
  padding-bottom: 20px;
}

.landing_foot_top .foot_left_box {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 328px;
}
.foot_left_box img {
  height: 50px;
  object-fit: contain;
}
.foot_left_box p {
  font-size: 14px;
  font-weight: 500;
  color: #828a8b;
}
.foot_left_box .contact_social {
  display: flex;
  gap: 20px;
  color: #828a8b;
}
.foot_left_box .contact_social span {
  cursor: pointer;
}

.landing_foot_top .foot_right_box {
  display: flex;
  gap: 40px;
}

.foot_right_box .foot_right_content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.foot_right_content h3 {
  color: #646c6d;
  font-size: 16px;
  font-weight: 600;
}
.foot_right_content p {
  color: #828a8b;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  cursor: pointer;
}

.landing_footer_bottom {
  height: 66px;
  background-color: #f3f3f3;
  width: 100%;
}
.landing_foot_bottom {
  max-width: 1440px;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}
.landing_foot_bottom .foot_bottom_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
}
.foot_bottom_left .foot_bottom_links {
  display: flex;
  gap: 20px;
  color: #828a8b;
  font-weight: 600;
  font-size: 14px;
}
.foot_bottom_left .foot_bottom_links span {
  cursor: pointer;
}
.foot_bottom_left p {
  margin-bottom: 0;
  color: #a4b1b2;
  font-weight: 500;
  font-size: 14px;
}

.foot_bottom_right .contact_social {
  display: flex;
  gap: 20px;
  color: #828a8b;
}
.foot_bottom_right .contact_social span {
  cursor: pointer;
}

@media (max-width: 1160px) {
  .landing_foot_top .foot_right_box {
    gap: 20px;
  }
  .foot_right_box .foot_right_content {
    gap: 8px;
  }
  .foot_right_content p {
    margin-bottom: 0rem;
  }
}
@media (max-width: 950px) {
  .landing_foot_top .foot_left_box {
    display: none;
  }
  .landing_foot_top .foot_right_box {
    gap: 30px;
  }
}
@media (max-width: 700px) {
  .landing_foot_top .foot_right_box {
    gap: 10px;
    flex-direction: column;
  }
  .foot_right_box .foot_right_content {
    flex-direction: column;
    gap: 0px;
    flex-wrap: wrap;
  }
  .foot_right_content h3 {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .foot_right_content p {
    margin-right: 10px;
  }
  .foot_right_content div {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
  }
}
@media (max-width: 600px) {
  .landing_footer {
    min-height: 390px;
    height: auto;
  }
  .landing_footer .landing_foot_top {
    padding: 20px;
  }
  .landing_footer_bottom {
    min-height: 66px;
    height: auto;
  }
  .landing_foot_bottom {
    flex-direction: column-reverse;
    align-items: start;
    padding: 10px 20px;
    gap: 10px;
  }
  .landing_foot_bottom .foot_bottom_left {
    gap: 0px;
  }
  .foot_bottom_left .foot_bottom_links {
    flex-wrap: wrap;
    gap: 0px;
  }
  .foot_bottom_left .foot_bottom_links span {
    margin-right: 20px;
  }
}
