.call-card-un {
  height: 100%;
  width: 100%;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border: 1px solid #ddd; /* Add borders for clarity */
  overflow: hidden;
}

.call-card-un.singleUser {
  height: 70%;
  width: 600px;
  max-width: 70%;
}
.call-card-un.onJoin {
  height: 370px;
  width: 350px;
}

@media (max-width: 800px) {
  .call-card-un.singleUser {
    height: 60%;
    width: 80%;
    max-width: 80%;
  }
  .call-card-un-text {
    font-size: 4em !important;
  }
  .call-card-un.onJoin {
    height: 350px;
    width: 350px;
    min-width: 60vw;
    max-width: 80vw;
  }
}
