.VsTVUAD89KWleD0YRVsD{
    background-color: #0597a6 !important;
}

.tQCB5Tj6hsWzMNzTg5og{
    background-color: #0597a6 !important;
}
.TTgLs8cpg66Z6CXgHGVA{
    background-color: #0597a6 !important;
    border-color: #0597a6 !important;
}

._XRS2rWR1HN9YsjmOPmm .nRumfFQWv_1Hnf9jP5qZ{
    color:#0597a6 !important;
}

._XRS2rWR1HN9YsjmOPmm:hover .nRumfFQWv_1Hnf9jP5qZ:hover{
    color:#fff !important;
    border-color: #0597a6 !important;
}

.IughcowXVrJ5wcOf6vH9{
    background-color: #0597a6 !important;
}

.j9ygOVxEl2nClTPs77Ta .mCx2N1NwuMWObjjTeG0q{
    color:#0597a6 !important;
}

.j9ygOVxEl2nClTPs77Ta:hover .mCx2N1NwuMWObjjTeG0q:hover{
    color:#fff !important;
}

.TYiiRFB3EhYJGVPE4k4q{
    outline:#0597a6 !important;
}