@keyframes pulse-button {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.nav-box .nav-open,
.nav-close {
  display: none;
}

@media (max-width: 900px) {
  .xo-menu {
    display: none;
  }
  .nav-top {
    width: 100% !important;
    justify-content: space-between !important;
  }
  .nav-content {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 70%;
    background: #0796a7;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
  }
  .nav-content.active {
    left: 0;
  }
  .nav-content ul {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 0;
  }
  .nav-content div div {
    color: #002c5f;
    padding: 20px 0px;
    text-align: center;
    width: 200px;
    margin: 0;
    border-radius: 5px;
    background-color: #fff;
    transition: all 0.2s ease;
  }
  .nav-content ul li:hover {
    background: #252525;
    color: #fff;
  }

  .nav-content .button {
    width: 200px;
    padding: 0px 50px;
    background-color: #fff;
    color: #002c5f;
    border: none;
  }
  .nav-content .button:hover {
    background: #252525;
    color: #fff;
  }

  .nav-box .nav-open {
    display: flex;
    justify-items: center;
    align-items: center;
    font-size: 30px;
    color: #ffff;
    transform: translateX(-15px);
    cursor: pointer;
  }
  .nav-box {
    font-size: 30px;
  }
  .nav-close {
    display: block;
    color: #fff;
    font-size: 40px;
    position: absolute;
    top: 23px;
    right: 30px;
    cursor: pointer;
  }
}
