.call-Container {
  display: grid;
  gap: 15px;
  justify-content: center;
  align-content: center;
  width: 100% !important;
  height: 100% !important;
  max-width: 1360px;
}

/* Specific layouts for different user counts */
.call-Container.users-1 > :only-child {
  width: 60%;
  height: 60%;
}
.call-Container.users-2 {
  grid-template-columns: repeat(2, 1fr);
}
.call-Container.users-2 > * {
  height: 50vh;
}

.call-Container.users-3 {
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
}
.call-Container.users-3 > :last-child {
  transform: translateX(50%);
}

.call-Container.users-4 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.call-Container.users-5 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.call-Container.users-6 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.call-Container.users-7 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.call-Container.users-7 > :last-child {
  grid-column-start: 2;
  grid-column-end: 3;
}

.call-Container.users-8 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.call-Container.users-9 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

@media (max-width: 1340px) {
}
@media (max-width: 1076px) {
}
@media (max-width: 800px) {
  .call-Container.users-2 {
    grid-template-columns: repeat(1, minmax(80%, auto));
    grid-template-rows: repeat(2, 1fr);
  }
  .call-Container.users-2 > * {
    height: 100%;
  }
  .call-Container.users-3 {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(1, minmax(80%, auto));
  }
  .call-Container.users-3 > :last-child {
    transform: translateX(0);
  }
  .call-Container.users-4 {
    height: 80%;
  }
  .call-Container.users-5 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .call-Container.users-6 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}

.no-internet {
  display: flex;
  position: absolute;
  top: 0;
  height: 23px;
  width: calc(100vw);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 187, 87);
  font-size: 11px;
  z-index: 10;
  color: black;
}
