.screenBox {
  height: 70vh;
  width: 70vw;
  border-radius: 20px;
}

@media (max-width: 1150px) {
  .screenBox {
    height: 65vh;
    width: 85vw;
  }
}
@media (max-width: 750px) {
  .screenBox {
    height: 40vh;
    width: 90vw;
  }
}
