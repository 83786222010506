.xb_button_container {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 25px;
  box-sizing: border-box;
  gap: 8px;
  letter-spacing: 1px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.xb_button_container.xb_button_primary {
  background: #0796a7;
  border: none;
  color: white;
}

.xb_button_container.xb_button_secondary {
  background-color: #ffffff;
  border: none;
  color: #068999;
}

.xb_button_text {
  transition: all 0.3s ease-in-out;
}

.xb_button_icon {
  width: 0px;
  height: 2px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  transform: translateY(-0px);
}
.xb_button_icon_2 {
  width: 0px;
  height: 2px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  transform: translateY(-0px);
}
.xb_button_secondary .xb_button_icon {
  background-color: #068999;
}
.xb_button_primary .xb_button_icon {
  background-color: #fff;
}

.xb_button_container:hover .xb_button_icon {
  width: 10px;
}
.xb_button_container:hover .xb_button_icon_2 {
  width: 20px;
}
.xb_button_container:hover .xb_button_text {
  margin-left: 0px;
}
.xb_button_container:hover {
  margin-right: 10px;
}

.xb_button_container:active {
  transform: translateY(2px);
  transition: transform 0.1s ease-in-out;
}
