.contact_us_container {
  max-width: 1280px;
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.contact_left_box {
  width: 45%;
  max-width: 460px;
  background-color: #068999;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.contact_left_box .img_vector {
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 18px;
}

.contact_left_box .contact_us_head {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  margin-bottom: 0.5rem;
}
.contact_left_box .contact_us_head_sub {
  color: #ececec;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}

.contact_left_box .contact_item {
  font-weight: 400;
  color: #ffffff;
  font-size: 16px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  margin-bottom: 1.5rem;
}

.contact_left_box .contact_item p {
  margin-bottom: 0;
}

.contact_left_box .contact_social {
  display: flex;
  gap: 20px;
  color: #ebebeb;
}

.contact_left_box .contact_social span {
  cursor: pointer;
}

@media (max-width: 800px) {
  .contact_us_container {
    flex-direction: column;
  }
  .contact_left_box {
    width: 100%;
    max-width: 100%;
    height: 600px;
  }
}
