@media (max-width: 643px) {
  .faq_header {
    flex-direction: column;
    align-items: center;
    gap: 0.2rem !important;
  }
}
@media (max-width: 600px) {
  .faq_tab_names {
    gap: 1rem !important;
  }
}
