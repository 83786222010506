.xb_accordion_box {
  position: relative;
  margin: 10px 20px;
  background: #fdfdfd;
  border: 1px solid #a4b1b2;
  border-radius: 8px;
}
.xb_accordion_box.active {
  border: 1px solid #5bbcc4;
  background: #f5ffff;
}

.xb_accordion_box .xb_accordion_label {
  position: relative;
  padding: 24px 30px;
  color: #596061;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.xb_accordion_box .xb_accordion_content {
  position: relative;
  padding: 0px 30px;
  height: 0;
  overflow: hidden;
  transition: height 0.5s;
  color: #828a8b;
  font-weight: 500;
  font-size: 18px;
}

.xb_accordion_box.active .xb_accordion_content {
  height: 150px;
  padding: 0px 30px 24px 30px;
  overflow-y: auto;
  scrollbar-width: none;
}

@media (max-width: 600px) {
  .xb_accordion_box {
    margin: 10px 0px;
  }
}
